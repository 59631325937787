import React from "react";
import PhotoDimmer from "./PhotoDimmer";

import { FirestoreDocument } from "@react-firebase/firestore";

export const getTextFromFirestore = (path) => {
  return (
    <FirestoreDocument path={`/Texts/${path}`}>
      {(d) => {
        if (!d.isLoading && d.value) {
          const { text } = d.value;
          return <div dangerouslySetInnerHTML={{ __html: text }} />;
        }
      }}
    </FirestoreDocument>
  );
};

export const getImageFromFirestore = (path) => {
  return (
    <FirestoreDocument path={`/images/${path}`}>
      {(d) => {
        if (!d.isLoading && d.value) {
          const { image, alt } = d.value;
          return (
            <div className="middle aligned column">
              <a href={image} download>
                <PhotoDimmer
                  image={image}
                  text={<i class="download icon"></i>}
                  size="big"
                  alt={alt}
                />
              </a>
            </div>
          );
        }
      }}
    </FirestoreDocument>
  );
};
