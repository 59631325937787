import React from "react";
import "./Biography.css";
import Gallery from "./Gallery";
import { getTextFromFirestore, getImageFromFirestore } from "./utils";

const Biography = () => {
  return (
    <div>
      <div className="Biography">
        <div className="ui two column stackable grid container">
          <div
            className="column ui text container"
            style={{ backgroundColor: "#AFE19F" }}
          >
            {getTextFromFirestore("DxPpFIMmHMI2iU8iw7Tq")}
            {getImageFromFirestore("RockCorps")}
          </div>
          <div className="column">
            {getTextFromFirestore("2SvU9WBl9d19BhqryF0x")}
          </div>
        </div>
      </div>
      <div className="Biography">
        <div className="ui stackable grid container">
          <div className="middle aligned column ten wide">
            <Gallery />
          </div>
          <div
            className="column six wide"
            style={{ backgroundColor: "#171D4B", color: "#f8f5e6" }}
          >
            {getTextFromFirestore("U7kzWF12beGMsZfLItUi")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Biography;
