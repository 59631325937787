import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div
      className="ui vertical segment footer"
      style={{ backgroundColor: "#171D48", marginTop: "30px" }}
    >
      <div className="ui center aligned four column grid">
        <div className="column">
          <a href="https://www.linkedin.com/in/stephenbgreene/">
            <i className="big linkedin icon"></i>
          </a>
        </div>
        <div className="column">
          <a href="https://www.instagram.com/sgreenes/">
            <i className="big instagram icon"></i>
          </a>
        </div>

        <div className="column">
          <a href="https://twitter.com/stephenbgreene">
            <i className="big twitter icon"></i>
          </a>
        </div>
        <div className="column">
          <a href="mailto:stephen@rockcorps.com">
            <i className="big envelope icon"></i>
          </a>
        </div>
      </div>
      <div className="ui text container">
        <br />
        <a href="https://www.linkedin.com/in/julien-le-cerf/">
          <p style={{ textAlign: "center", fontSize: "14px" }}>
            Designed by Julien Le Cerf
          </p>
        </a>
      </div>
    </div>
  );
};

export default Footer;
