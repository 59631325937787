import React from "react";
import Stephen from "./Stephen";
import Footer from "./Footer";
import Biography from "./Biography";
import { FirestoreProvider } from "@react-firebase/firestore";
import firebase from "firebase";
import "./App.css";
import { config } from "../Config";

const App = () => {
  return (
    <FirestoreProvider {...config} firebase={firebase}>
      <div>
        <div className="ui container Main-page">
          <Stephen />
          <Biography />
        </div>
        <div className="ui fluid container">
          <Footer />
        </div>
      </div>
    </FirestoreProvider>
  );
};

export default App;
