import React from "react";
import Portrait from "./Data/Stephen.jpg";
import "./Stephen.css";
import "semantic-ui-css/semantic.min.css";
import { getTextFromFirestore } from "./utils";

const Stephen = () => {
  return (
    <div className="Stephen">
      <div className="ui stackable three column grid Stephen">
        <div className="middle aligned column one">
          <div className="ui large circular image">
            <img src={Portrait} alt="Portrait de Stephen Greene" />
          </div>
        </div>
        <div className="middle aligned column two">
          <div className="ui text container">
            {getTextFromFirestore("gM0I5UPHJp4UZryWSBV0")}
            {getTextFromFirestore("0p4qg6hMyWzRUDAuo9Xm")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stephen;
