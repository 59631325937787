import React from "react";
import "./Gallery.css";
import { getImageFromFirestore } from "./utils";

const Gallery = () => {
  return (
    <div className="ui container">
      <div className="ui three column doubling centered grid">
        <div className="row">
          {getImageFromFirestore("hxXPJfSP8XUEzkUhLDvO")}
          {getImageFromFirestore("K8zKpAmztKBJ0JHTqOM3")}
        </div>
        <div className="row">
          {getImageFromFirestore("VNReKZNDGXIxuW1l6FVT")}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
