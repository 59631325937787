import React, {Component} from 'react';
import { Dimmer, Header, Image } from 'semantic-ui-react';


class PhotoDimmer extends Component {
  state={}
  handleShow = () => this.setState({ active: true })
  handleHide = () => this.setState({ active: false })

  render(props) {
    const { active } = this.state
    const content = (
      <div>
        <Header as='p' inverted>
          {this.props.text}
        </Header>
      </div>
    )
    return (
      <Dimmer.Dimmable
        as={Image}
        dimmed={active}
        dimmer={{ active, content }}
        onMouseEnter={this.handleShow}
        onMouseLeave={this.handleHide}
        size={this.props.size}
        src={this.props.image}
        alt={this.props.alt}
      />
    )
  }
}

export default PhotoDimmer;