export const config = {
  apiKey: "AIzaSyBzEvBfzWQJ9xBpFdICFBTiF910wOn15RY",
  authDomain: "website-sg-20835.firebaseapp.com",
  databaseURL: "https://website-sg-20835-default-rtdb.firebaseio.com",
  projectId: "website-sg-20835",
  storageBucket: "website-sg-20835.appspot.com",
  messagingSenderId: "711119081215",
  appId: "1:711119081215:web:c96c620b3ecdd3ef1146ca",
  measurementId: "G-48EQSYD6MW",
};
